import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import { rgba, darken } from 'polished';
import { Link } from 'gatsby';
import { media } from './theme';
import useSiteContext from './SiteContext';

const NavItem = ({ className, externalUrl, label, link, blogArchive, homePage }) => {
  const { width } = useSiteContext();

  if (externalUrl) {
    return (
      <External className={classNames(className, 'external-link')} href={externalUrl} target="_blank" width={width}>
        {label}
      </External>
    );
  }
  if (link || blogArchive) {
    const url = homePage.id === link.id ? '/' : `/${blogArchive ? 'blog' : link.slug?.current || ''}`;
    return (
      <Internal className={classNames(className, 'internal-link')} to={url} width={width} activeClassName="current">
        {label || link.title}
      </Internal>
    );
  }
  return <Generic className={classNames(className, 'generic')}>{label}</Generic>;
};

const itemStyles = css`
  display: block;
  color: ${({ theme }) => theme.dark};
  text-decoration: none;
  text-transform: uppercase;
  padding: 1rem 3rem;
  font-size: 2.6rem;
  font-weight: ${({ theme }) => theme.font.bold};
  border-bottom: 2px solid white;
  &.current {
    background: ${({ theme }) => theme.red};
    color: white;
  }
  &.donate-button {
    background: ${({ theme }) => theme.lightBlue};
    color: white;
    border-bottom: 0;
    :hover:not(.current) {
      background: ${({ theme }) => darken(0.1, theme.lightBlue)};
    }
    ${media.break`
      font-size: 2rem;
    `}
  }
  transition: 0.25s;
  :hover {
    text-decoration: none;
    :not(.current) {
      background: ${({ theme }) => rgba(theme.lightBlue, 0.25)};
      /* color: white; */
    }
  }
  ${media.break`
    &.donate-button {
      position: absolute;
      top: 2.5rem;
      right: 8rem;
      @media (min-width: 1324px) {
        right: ${({ width }) => (width - 1324) / 2 + 40}px;
      }
    }
  `}
`;

const External = styled.a`
  ${itemStyles}
`;
const Internal = styled(Link)`
  ${itemStyles}
`;
const Generic = styled.span`
  ${itemStyles}
`;
export default NavItem;
