import React from 'react';

import Wrapper from './src/components/Wrapper';

export const wrapPageElement = ({ element, props }) => {
  return (
    <Wrapper {...props.data} home={props.path === '/'} blog={props.path === '/blog/'} location={props.location} url={props.location.href}>
      {element}
    </Wrapper>
  );
};
