import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import classNames from 'classnames';

import useViewportSizes from 'use-viewport-sizes';
import useMeasure from 'react-use-measure';

import Meta from './Meta';
import Header from './Header';
import Footer from './Footer';

import { SiteContextProvider } from './SiteContext';
import theme, { media } from './theme';
import GlobalStyle from './GlobalStyle';
// import useMeasure from '../lib/useMeasure';

const Wrapper = props => {
  const { home = false, blog = false, children, className, data, location } = props;
  const { seoOptions } = props.sanityPage || props.sanityPost || { seoOptions: null };
  const { title } = props.sanityPage || props.sanityPost || { title: null };

  const [width, height, updateVpSizes] = useViewportSizes();

  useEffect(() => {
    if (!height) {
      updateVpSizes();
    }
  }, []);

  const [ref, sizes] = useMeasure();

  return (
    <ThemeProvider theme={theme}>
      <SiteContextProvider home={home} data={{ height, width, pageContentSize: sizes }}>
        <SiteWrapper className={classNames('site-wrapper', className)} viewHeight={height}>
          <Meta seo={seoOptions} title={title} home={home} blog={blog} location={location} />
          <Header className="header" />
          <PageContainer className="page-container" ref={ref}>
            {children}
          </PageContainer>
          <Footer className="footer-wrapper" />
          <GlobalStyle />
        </SiteWrapper>
      </SiteContextProvider>
    </ThemeProvider>
  );
};

const PageContainer = styled.div`
  ${media.break`
    max-width: calc(100vw - 315px);
  `}
`;

const SiteWrapper = styled.div`
  min-height: ${({ viewHeight }) => viewHeight}px;
  display: grid;
  /* grid-template-rows: ${({ theme }) => theme.sizes.header}px 1fr auto; */
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;

  ${media.break`
    display: flex;
    flex-wrap: wrap;
    .header {
      flex: 0 0 300px;
    }
    .page-container {
      flex: 0 1 900px;
      flex: 0 1 calc(100vw - 320px);
      margin: 0;
      background: ${({ theme }) => theme.light};
      padding: 5rem 4rem;
    }
    .footer-wrapper {
      flex: 0 0 100%;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 300px 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: 
        "header content"
        "footer footer";
      
      .header {
        grid-area: header;
      }
      .page-container {
        grid-area: content;
        /* width: 100%; */
      }
      .footer-wrapper {
        grid-area: footer;
        /* width: 100%; */
      }
    }
  `}
`;

export default Wrapper;
