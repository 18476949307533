import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { globalHistory } from '@reach/router';

import NavToggle from './NavToggle';
import Nav from './Nav';
import { media } from './theme';

const Header = ({ className }) => {
  const {
    sanityGeneralSettings: { logo, siteName },
  } = useStaticQuery(graphql`
    {
      sanityGeneralSettings {
        siteName
        logo {
          asset {
            gatsbyImageData(width: 250)
          }
        }
      }
    }
  `);

  const logoSrc = getImage(logo.asset);

  const [navOpen, toggleNav] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') toggleNav(false);
    });
  }, [toggleNav]);

  return (
    <StyledHeader className={className}>
      <NavToggle className="nav-toggle" open={navOpen} toggle={toggleNav} />

      <Link to="/" className="header-logo">
        <GatsbyImage image={logoSrc} alt={`${siteName} logo`} />
      </Link>

      <Nav open={navOpen} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 1.5rem 1rem;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  height: 250px;
  z-index: 2;
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 250px 1fr;
    justify-items: center;
  }

  ${media.break`
    display: block;
    height: auto;
    padding: 0;
    position: static;
    .header-logo {
      display: block;
      width: 100%;
      padding: 1.5rem 1rem;
    }
  `}

  .nav-toggle {
    ${media.break`
      display: none;
    `}
  }
`;
export default Header;
