import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { darken } from 'polished';

const Button = props => {
  const { link, text, className, backgroundColor, externalUrl } = props;

  if (externalUrl) {
    return (
      <StyledExternalLink
        className={classNames(className, 'button button--external-link')}
        href={externalUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </StyledExternalLink>
    );
  }
  if (link) {
    return (
      <StyledLink className={classNames(className, 'button button--link')} to={link.slug.current} background={backgroundColor}>
        {text}
      </StyledLink>
    );
  }
  return (
    <StyledButton className={classNames(className, 'button button')} background={backgroundColor}>
      {text}
    </StyledButton>
  );
};

const ButtonStyles = css`
  background: ${({ theme, background }) => background || theme.blue};
  color: white;
  text-decoration: none;
  padding: 1rem 1.5rem;
  font-size: 2.2rem;
  display: inline-block;
  text-align: center;
  border: 0;
  .content-button-wrapper & {
    color: white;
  }
  :hover {
    background: ${({ theme, background }) => darken(0.1, background || theme.blue)};
  }
`;

const StyledExternalLink = styled.a`
  ${ButtonStyles}
  :hover {
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)`
  ${ButtonStyles}
  :hover {
    text-decoration: none;
  }
`;
const StyledButton = styled.button`
  ${ButtonStyles}
  cursor: pointer;
`;

export default Button;
