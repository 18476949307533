import React from 'react';
import styled from 'styled-components';

const NavToggle = ({ className, open, toggle }) => {
  return (
    <Toggle className={className} onClick={() => toggle(!open)} open={open}>
      <span />
      <span />
      <span />
    </Toggle>
  );
};

const Toggle = styled.button`
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
  position: relative;
  span {
    position: absolute;
    background: ${({ theme }) => theme.dark};
    display: block;
    width: 30px;
    height: 2.5px;
    transition: 0.25s;
    &:nth-child(1) {
      top: 50%;
      transform: translateY(-8px);
      transform: ${({ open }) => (open ? 'translateY(0px) rotate(45deg)' : 'translateY(-8px)')};
    }
    &:nth-child(2) {
      top: 50%;
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      top: 50%;
      transform: translateY(8px);
      transform: ${({ open }) => (open ? 'translateY(0px) rotate(-45deg)' : 'translateY(8px)')};
    }
  }
`;

export default NavToggle;
