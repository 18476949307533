import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Meta = props => {
  const { seo, title, home, blog, location } = props;

  const {
    sanityGeneralSettings: { customCSS, siteName },
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    {
      sanityGeneralSettings {
        customCSS {
          code
        }
        siteName
      }
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `);

  const seoTitle = seo && seo.title ? seo.title : title;
  const titleTag = home ? 'Home' : blog ? 'Blog' : seoTitle;
  const openUrl = `${siteUrl}${location.pathname}`;

  return (
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/hef2urc.css" />
      {customCSS?.code && <style>{customCSS.code}</style>}
      <meta property="og:title" content={titleTag} />
      <meta property="og:url" content={openUrl} />

      <title>{`${!home ? `${titleTag} | ` : ''}${siteName}`}</title>
      {seo?.metaDescription && <meta property="og:description" content={seo.metaDescription} />}
      {seo?.metaDescription && <meta name="description" content={seo.metaDescription} />}
    </Helmet>
  );
};

export default Meta;
