import React from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import styled from 'styled-components';
import libphonenumber from 'google-libphonenumber';
import { media } from './theme';

import Button from './Button';

const isEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

const customSerializers = {
  types: {
    button: ({ node }) => {
      const { alignment } = node;
      return (
        <ContentButtonWrapper className="content-button-wrapper" alignment={alignment}>
          <Button className="content-button" {...node} />
        </ContentButtonWrapper>
      );
    },
  },
  marks: {
    callout: props => <Callout className="callout">{props.children}</Callout>,
    center: props => <span className="text-center">{props.children}</span>,
    link: props => {
      if (props.mark.href) {
        return <a href={props.mark.href}>{props.children.join(' ')}</a>;
      }
      if (isEmail(props.children[0])) {
        return <a href={`mailto:${props.children[0]}`}>{props.children.join(' ')}</a>;
      }
      const number = phoneUtil.parseAndKeepRawInput(props.children[0], 'US');
      if (phoneUtil.isPossibleNumber(number)) {
        return <a href={`tel:${phoneUtil.format(number, PNF.INTERNATIONAL)}`}>{phoneUtil.formatInOriginalFormat(number, 'US')}</a>;
      }
      return props.children.join(' ');
    },
  },
  // marks: {
  //   center: props => {
  //     return <span className="text-center">{props.children}</span>;
  //   },
  //   file: ({
  //     children,
  //     mark: {
  //       asset: { url },
  //     },
  //   }) => {
  //     return (
  //       <a class="file-link" href={url} target="_blank" rel="noreferrer">
  //         {children}
  //       </a>
  //     );
  //   },
  //   link: ({ children, mark }) => {
  //     const { externalUrl, url } = mark;
  //     if (externalUrl) {
  //       return (
  //         <a href={externalUrl} target="_blank" rel="noopener noreferrer">
  //           {children}
  //         </a>
  //       );
  //     }
  //     return <Link to={`/${url?.slug?.current || null}`}>{children}</Link>;
  //   },
  //   id: ({ children, mark }) => {
  //     return mark.id ? <span id={mark.id.current}>{children}</span> : children;
  //   },
  // },
};

const Content = React.forwardRef(({ className, children, style }, ref) => {
  return (
    <Container style={style} className={classNames('block-content', className)} ref={ref}>
      {children && (
        <BlockContent
          blocks={children}
          serializers={customSerializers}
          className="block-content-wrapper"
          renderContainerOnSingleChild={true}
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  padding: 0 1rem;
  blockquote {
    font-family: ${({ theme }) => theme.font.special};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.red};
    text-align: center;
    font-size: 2.4rem;
    margin: 1rem 0;
    line-height: 1.5;
    ${media.break`
      font-size: 3.6rem;
    `}
  }
  font-size: 1.8rem;
  p,
  li {
    color: ${({ theme }) => theme.navy};
    line-height: 1.5;
    margin-top: 0;
    font-size: inherit;
    ${media.break`
      
    `}
    &.large-text {
      color: ${({ theme }) => theme.dark};
      font-size: 2.2rem;
    }
  }
  h1,
  h2,
  h3,
  h4 {
    > span {
      display: block;
    }
  }
  h1 {
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.purple};
    text-align: center;
    font-size: 2.4rem;
    margin: 1rem 0;
    line-height: 1.5;
    ${media.break`
    font-size: 3.6rem;
    `}
  }
  h2 {
    color: ${({ theme }) => theme.blue};
    font-weight: ${({ theme }) => theme.font.bold};
    text-transform: uppercase;
    margin: 1rem 0;
    font-size: 2.6rem;
  }

  h3 {
    margin: 1rem 0 0.5rem;
  }
  .columns {
    ${media.break`
      display: flex;
      align-items: center;
      .column {
        flex: 0 0 50%;
        padding: 1rem 2rem;
      }
    `}
  }
`;

const Callout = styled.span`
  color: ${({ theme }) => theme.lightBlue};
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.font.medium};
`;

const ContentButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({ alignment }) => (alignment === 'center' ? 'center' : alignment === 'right' ? 'end' : 'start')};
`;

const StyledListBlock = styled.div`
  padding: 1rem;
  margin: 1rem 0;

  .block-content-wrapper {
    > * {
      margin-top: 0;
    }
    p,
    li {
      color: ${({ theme }) => theme.red};
      font-size: 2.4rem;
      font-weight: ${({ theme }) => theme.font.bold};
      text-align: center;
    }
    ${media.break`
    columns: 2;
    `}
  }
`;

export default Content;
