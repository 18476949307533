import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import NavItem from './NavItem';
import useSiteContext from './SiteContext';
import theme, { media } from './theme';

const Nav = ({ open }) => {
  const { height, pageContentSize } = useSiteContext();

  const {
    sanityGeneralSettings: { mainMenu, donateButton, homePage },
  } = useStaticQuery(graphql`
    {
      sanityGeneralSettings {
        homePage {
          id
        }
        mainMenu {
          _key
          externalUrl
          label
          link {
            title
            slug {
              current
            }
            id
          }
          blogArchive
        }
        donateButton {
          alignment
          backgroundColor
          externalUrl
          text
          link {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const navHeight = pageContentSize.height < height - theme.sizes.header ? pageContentSize.height : height - theme.sizes.header;

  return (
    <StyledNav className="nav" height={navHeight} open={open}>
      <ul className="nav-menu">
        {mainMenu.map(item => (
          <NavItem className="nav-item" key={item._key} {...item} homePage={homePage} />
        ))}
        <NavItem className="nav-item donate-button" {...donateButton} label={donateButton.text} />
      </ul>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  position: absolute;
  top: 100%;
  left: 0;
  width: 80%;
  background: ${({ theme }) => theme.light};
  /* height: ${({ height }) => height}px; */
  transition: 0.25s;
  transform: ${({ open }) => (open ? 'translateX(0%)' : 'translateX(-100%)')};
  ${media.break`
    transform: none;
    position: static;
    height: auto;
    margin-left: auto;
    width: 90%;
    transition: 0s;
  `}
  .nav-menu {
    margin: 0;
    padding: 0;
  }
`;

export default Nav;
